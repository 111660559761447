import {
  CategoriesDisplayOptions,
  MultiOfferingsDisplayOptions,
} from '../display-options/offering-list-widget-display-options';
import {
  AlignmentOptions,
  CategoriesType,
  CategoryLayoutOptions,
  OfferingListLayoutOptions,
} from '../../Shared/appKeys/SettingsKeys';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import { VARIANT } from 'wix-ui-tpa/dist/src/components/Tabs/constants';
import { Experiments } from '../../Shared/experiments/experiments';
import {
  ServiceLocation,
  ReservedLocationIds,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';
import { OfferingDomain } from './offering-domain';

import { CategoriesItem } from '../components/MultiOfferings/Categories/Categories';
import { AllServicesCategoryExposureInfo } from '../adapters/reporting/bi-logger/bi.const';

export interface IMultiOfferingsViewModel {
  title: TitleViewModel;
  layout: string;
  categoriesViewModel: CategoriesViewModel;
  maxItemsPerRow: number;
  spaceBetweenOfferings: number;
  withDivider: boolean;
  dividerWidth: number;
  cardMinWidth: number;
  cardMaxWidth: number;
  rtl?: boolean; // todo - should be removed when yoshi will fix rtl!!!
  uniformRowHeight: boolean;
}

export interface TitleViewModel {
  text: string;
  isVisible: boolean;
  alignment: AlignmentOptions;
  htmlTag: string;
}

export interface CategoriesViewModel {
  isVisible: boolean;
  alignment: AlignmentOptions;
  variant: string;
  categoriesType: CategoriesType;
  categories: OfferingCategoryDto[];
  locations: CategoriesItem[];
  layout: CategoryLayoutOptions;
  allServicesCategoryExposureInfo: AllServicesCategoryExposureInfo;
}

export enum ListLayoutOptions {
  COLUMN = 'COLUMN',
  GRID = 'GRID',
}

export const CARD_MAX_WIDTH = 616;
export const CARD_MIN_WIDTH = 220;
export const MOBILE_CARD_MIN_WIDTH = 130;

function getTitle(title, formatter: Function) {
  return title || formatter('multi-offerings.title');
}

function getUniformRowHeight(isMobile) {
  return !isMobile;
}

function getSpacingBetweenOfferings(
  layout,
  spacing,
  stripSpacing,
  cardsSpacing,
) {
  return layout === OfferingListLayoutOptions.GRID
    ? cardsSpacing
    : layout === OfferingListLayoutOptions.STRIP
    ? stripSpacing
    : spacing;
}

export function mapLocationToCategoryItem(
  location: ServiceLocation,
): CategoriesItem {
  return {
    title: location.businessLocation.name,
    id: location.businessLocation.id,
  };
}

function getAllServicesCategory(categoriesDisplayOptions, formatter) {
  const name =
    categoriesDisplayOptions.allServicesCategoryText ||
    formatter('category-name.all-services');
  return {
    name,
    title: name,
  };
}

function getLocationCategoriesTypeItems(
  categoriesDisplayOptions: CategoriesDisplayOptions,
  locations: ServiceLocation[],
  offeringsDomain: OfferingDomain[],
  formatter: (string) => string,
  experiments: Experiments,
) {
  const shouldShowOtherLocationsCategory = offeringsDomain.some((service) =>
    service.locations.find(
      (location) => location.type !== ServiceLocationType.OWNER_BUSINESS,
    ),
  );
  const sortCategoriesAlphabetically = (categoryItem1, categoryItem2) =>
    categoryItem1.title > categoryItem2.title ? 1 : -1;
  const businessLocations = (locations || [])
    .map(mapLocationToCategoryItem)
    .sort(sortCategoriesAlphabetically);
  const synteticCategories = [];

  if (
    experiments.isShowAllServicesCategoryEnabled &&
    categoriesDisplayOptions.isShowAllServicesCategory
  ) {
    synteticCategories.push(
      getAllServicesCategory(categoriesDisplayOptions, formatter),
    );
  }
  return [
    ...synteticCategories,
    ...businessLocations,
    ...(shouldShowOtherLocationsCategory
      ? [
          {
            title: formatter(
              'service-list.categories.location-categories.other-locations',
            ),
            id: ReservedLocationIds.OTHER_LOCATIONS,
          },
        ]
      : []),
  ];
}

function getCategoriesWithTranslations(categories, formatter) {
  return categories.map((category) => ({
    ...category,
    name: formatter(category.name),
  }));
}

function enrichCategories(
  categoriesDisplayOptions: CategoriesDisplayOptions,
  categories,
  formatter,
  experiments: Experiments,
): OfferingCategoryDto[] {
  if (
    experiments.isShowAllServicesCategoryEnabled
      ? categoriesDisplayOptions.isShowAllServicesCategory
      : categoriesDisplayOptions.layout === CategoryLayoutOptions.DROPDOWN
  ) {
    return [
      getAllServicesCategory(categoriesDisplayOptions, formatter),
      ...categories,
    ];
  }
  return categories;
}

const hasAllServicesOption = (arr: any[]) => !!arr?.find((item) => !item?.id);

export class MultiOfferingsViewModelFactory {
  static createMultiOfferingsViewModel(
    multiOfferingsDisplayOptions: MultiOfferingsDisplayOptions,
    offeringsDomain: OfferingDomain[],
    categories: OfferingCategoryDto[], // todo: change to category items
    locations: ServiceLocation[],
    formatter: (string) => string,
    rtl: boolean, // todo - should be removed when yoshi will fix rtl!!!
    isMobile: boolean,
    isDummyMode: boolean,
    experiments: Experiments = {},
  ): IMultiOfferingsViewModel {
    const titleView = {
      text: getTitle(multiOfferingsDisplayOptions.title.text, formatter),
      isVisible: multiOfferingsDisplayOptions.isTitleVisible,
      htmlTag: multiOfferingsDisplayOptions.title.htmlTag,
      alignment: multiOfferingsDisplayOptions.titleAlignment,
    };
    const categoriesDisplayOptions: CategoriesDisplayOptions =
      multiOfferingsDisplayOptions.categoriesDisplayOptions;
    const locationCategoryItems = experiments.isUoUMultiLocationV1Enabled
      ? getLocationCategoriesTypeItems(
          categoriesDisplayOptions,
          locations,
          offeringsDomain,
          formatter,
          experiments,
        )
      : [];
    const enrichedCategories = enrichCategories(
      categoriesDisplayOptions,
      categories,
      formatter,
      experiments,
    );

    const categoriesType = experiments.isUoUMultiLocationV1Enabled
      ? categoriesDisplayOptions.categoriesType
      : CategoriesType.SERVICE_CATEGORIES;
    const isVisible =
      categoriesDisplayOptions.isCategoriesVisible &&
      (categoriesType === CategoriesType.SERVICE_LOCATIONS
        ? locationCategoryItems?.length > 1
        : categories?.length > 1);
    const categoriesViewModel: CategoriesViewModel = {
      isVisible,
      layout: categoriesDisplayOptions.layout,
      alignment: categoriesDisplayOptions.categoriesContentAlignment,
      variant: categoriesDisplayOptions.isCategoriesContentStretch
        ? VARIANT.fullWidth
        : VARIANT.fit,
      categories: isDummyMode
        ? getCategoriesWithTranslations(enrichedCategories, formatter)
        : enrichedCategories,
      locations: locationCategoryItems,
      categoriesType,
      allServicesCategoryExposureInfo: {
        isExposedToTest: experiments.isShowAllServicesCategoryEnabled,
        allServicesCategoryShown:
          isVisible && categoriesType === CategoriesType.SERVICE_LOCATIONS
            ? hasAllServicesOption(locationCategoryItems)
            : hasAllServicesOption(enrichedCategories),
        allServicesCategorySettingsValue: !!categoriesDisplayOptions.isShowAllServicesCategory,
        isMobile,
      },
    };

    const offeringListLayout = ListLayoutOptions.COLUMN;

    const dividerWidth =
      isMobile ||
      multiOfferingsDisplayOptions.layout !== OfferingListLayoutOptions.STRIP
        ? 0
        : multiOfferingsDisplayOptions.dividerWidth;

    const maxItemsPerRow =
      isMobile ||
      multiOfferingsDisplayOptions.layout !== OfferingListLayoutOptions.GRID
        ? 1
        : multiOfferingsDisplayOptions.maxItemsPerRow;
    const cardMinWidth = isMobile
      ? MOBILE_CARD_MIN_WIDTH
      : multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.GRID
      ? CARD_MIN_WIDTH
      : 560;
    const withDivider =
      !isMobile &&
      multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.STRIP &&
      multiOfferingsDisplayOptions.isDividerVisible;
    const spaceBetweenOfferings = isMobile
      ? multiOfferingsDisplayOptions.cardsSpacing
      : getSpacingBetweenOfferings(
          multiOfferingsDisplayOptions.layout,
          multiOfferingsDisplayOptions.spaceBetweenOfferings,
          multiOfferingsDisplayOptions.stripSpaceBetweenOfferings,
          multiOfferingsDisplayOptions.cardsSpacing,
        );
    const uniformRowHeight = getUniformRowHeight(isMobile);
    const cardMaxWidth =
      multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.GRID &&
      !isMobile
        ? CARD_MAX_WIDTH
        : null;

    return {
      title: titleView,
      layout: offeringListLayout,
      categoriesViewModel,
      dividerWidth,
      maxItemsPerRow,
      spaceBetweenOfferings,
      cardMinWidth,
      cardMaxWidth,
      withDivider,
      uniformRowHeight,
    };
  }
}
