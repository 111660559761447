import * as React from 'react';
import {
  HoverEffectOptions,
  LoadingBehaviorOptions,
  ResizeOptions,
  ThumbnailImage,
} from 'wix-ui-tpa/ThumbnailImage';
import { OfferingImage } from '../../OfferingImage/OfferingImage';
import { ImageViewModel } from '../../../domain/offering-view-model-factory';
import { st, classes } from './OfferingViewImage.st.css';
import {
  ImageResizeOptions,
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../../../Shared/appKeys/SettingsKeys';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../../Shared/context/experiments-context';
import {
  getFitImageUrl,
  getFillImageUrl,
} from '../../../adapters/media-gallery/media-gallery-adapter';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import { DEFAULT_IMAGE_CONTAINER } from '../OfferingView.const';

interface OfferingViewImageProps
  extends RunningEnvironmentProps,
    ExperimentsProps {
  imageViewModel: ImageViewModel;
  width: number;
  height: number;
  onClick: any;
  forwardedRef: any;
  layout: OfferingListLayoutOptions;
  title: string;
}

class OfferingViewImageComponent extends React.PureComponent<OfferingViewImageProps> {
  private getImageUrl(isRoundGrid) {
    const { width, height, imageViewModel, runningEnvironment } = this.props;
    const { isSEO } = runningEnvironment;

    const { imageResize, mediaItem, focalPoint } = imageViewModel;

    const dimentions = isSEO
      ? {
          width: isRoundGrid ? mediaItem.height : mediaItem.width,
          height: mediaItem.height,
        }
      : { width: isRoundGrid ? height : width, height };

    if (imageResize === ImageResizeOptions.CROP) {
      return getFillImageUrl(mediaItem, dimentions, focalPoint);
    }
    return getFitImageUrl(mediaItem, dimentions);
  }

  render() {
    const {
      imageViewModel,
      height,
      onClick,
      layout,
      forwardedRef,
      title,
      experiments,
      runningEnvironment,
    } = this.props;
    const {
      shape,
      imageResize,
      mediaItem,
      focalPoint,
      aspectRatio,
    } = imageViewModel;
    const { isSEO } = runningEnvironment;

    const isRoundGrid =
      shape === ImageShapeOptions.ROUND &&
      layout === OfferingListLayoutOptions.GRID;

    const width = isRoundGrid ? height : this.props.width;
    const innerPadding = isRoundGrid ? 32 : 0;

    return (
      <div className={classes.imageWrapper} ref={forwardedRef}>
        <div
          className={st(classes.root, {
            shape: isRoundGrid ? 'round' : 'square',
          })}
          style={{
            height: isRoundGrid ? `calc(100% - ${innerPadding}px)` : null,
            width: isRoundGrid
              ? `calc(${100 / aspectRatio}% - ${innerPadding}px)`
              : null,
          }}
        >
          <div
            data-hook="offering-view-image"
            onClick={onClick}
            className={classes.clickable}
            role="presentation"
            tabIndex={-1}
            style={{
              height: experiments.isBookingsWidgetUseThumbnailImageEnabled
                ? '100%'
                : experiments?.isRenderImgElement &&
                  height !== DEFAULT_IMAGE_CONTAINER.height
                ? height
                : '100%',
              width: experiments.isBookingsWidgetUseThumbnailImageEnabled
                ? '100%'
                : experiments?.isRenderImgElement &&
                  width !== DEFAULT_IMAGE_CONTAINER.width
                ? width
                : '100%',
              position: 'relative',
            }}
          >
            {experiments.isBookingsWidgetUseThumbnailImageEnabled ? (
              mediaItem ? (
                <ThumbnailImage
                  key={`image-key-${height}-${width}-${imageResize}-${focalPoint.x}-${focalPoint.y}-${aspectRatio}`}
                  alt={title}
                  fluid
                  width={isSEO ? mediaItem.width : width}
                  height={isSEO ? mediaItem.height : height}
                  focalPoint={focalPoint}
                  loadingBehavior={LoadingBehaviorOptions.blur}
                  aspectRatio={aspectRatio}
                  hoverEffect={HoverEffectOptions.darken}
                  resize={
                    imageResize === ImageResizeOptions.CROP
                      ? ResizeOptions.cover
                      : ResizeOptions.contain
                  }
                  data-hook="image-container"
                  className={classes.rootImg}
                  src={this.getImageUrl(isRoundGrid)}
                />
              ) : null
            ) : (
              <OfferingImage
                width={width}
                height={height}
                aspectRatio={aspectRatio}
                imageResize={imageResize}
                mediaItem={mediaItem}
                focalPoint={focalPoint}
                title={title}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const OfferingViewImage = withRunningEnvironmentContext(
  withExperimentsContext(OfferingViewImageComponent),
);
