import * as React from 'react';
import { classes } from './OfferingOnlineIndication.st.css';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Camera } from 'wix-ui-tpa/dist/src/assets/icons/index';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

export interface OfferingOnlineIndicationProps extends RunningEnvironmentProps {
  children?: any;
}

const OfferingOnlineIndicationComponent = (
  props: OfferingOnlineIndicationProps,
) => {
  const { children } = props;

  return (
    <Badge
      key="online-indication-badge"
      data-hook="online-indication-badge"
      icon={<Camera />}
      priority={BADGE_PRIORITY.light}
      className={classes.root}
    >
      {children}
    </Badge>
  );
};

export const OfferingOnlineIndication = withRunningEnvironmentContext(
  OfferingOnlineIndicationComponent,
);
